import Vue from 'vue'
import Router from 'vue-router'

import store from '../store.js';

import Main from '../views/CheckRegistry.vue'
import Login from '../components/Login.vue'
import DenyAccess from '../components/DenyAccess.vue'

Vue.use(Router)

const ifAuthenticated = (to, from, next) => {
    if (!store.getters.isLoggedIn) {
        next('/login');
        return;
    }else if (!store.getters.getRule(to.name)){
        next('/access-deny');
        return;
    }
    next();
};


export default new Router({
  mode: 'history',
  hash: false,
  routes: [
    {
        path: '/main',
        name: 'Main',
        component: Main,
        beforeEnter: ifAuthenticated
    }, {
	    path: '/login',
        name: 'login',
        component: Login
    }, {
          path: '/access-deny',
          name: 'accessdeny',
          component: DenyAccess
    }, {
	path: '/settings',
        name: 'settings',
        component: () => import('../views/Settings.vue'),
        beforeEnter: ifAuthenticated,
        children: [{
            path: 'rules',
            name: 'rules',
            component: () => import('../views/Settings/Rules.vue'),
            beforeEnter: ifAuthenticated
        }, {
            path: 'checkregistry',
            name: 'setting-checkregistry',
            component: () => import('../views/Settings/CheckRegistryRules.vue'),
            beforeEnter: ifAuthenticated
        }, {
            path: 'logs',
            name: 'logs',
            component: () => import('../views/Settings/Logs.vue'),
            beforeEnter: ifAuthenticated
        }, {
            path: 'dict-stores',
            name: 'dict-stores',
            component: () => import('../views/Settings/DictStores.vue'),
            beforeEnter: ifAuthenticated
        }, {
            path: 'info',
            name: 'info',
            component: () => import('../views/Settings/Info.vue'),
            beforeEnter: ifAuthenticated
        }, {
            path: 'main',
            name: 'setting-main',
            component: () => import('../views/Settings/Main.vue'),
            beforeEnter: ifAuthenticated
        }]
    },{
        path: '/checkregistry',
        name: 'checkregistry',
        component: () => import('../views/CheckRegistry.vue'),
        beforeEnter: ifAuthenticated
    },{
        path: '/checkregistry/:id',
        name: 'checkregistry-item',
        component: () => import('../views/CheckRegistryData.vue'),
        beforeEnter: ifAuthenticated
    },{
        path: '/invoices',
        name: 'invoices',
        component: () => import('../views/Invoices.vue'),
        beforeEnter: ifAuthenticated
    }
  ]
})
